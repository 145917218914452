<template>
  <ion-page>
    <syaberu-menu/>
    <ion-content :fullscreen="true">
        <transition
         name="custom-classes-transition"
         enter-active-class="animate__animated animate__flipInY"
        >
        <div v-if="display">
       <ion-list-header><h3>ミッション達成状況</h3></ion-list-header>
       <div class="ion-margin">
       {{howMuchAchived * 100}}% 達成 ({{currentStage}}/{{total}})
       <ion-progress-bar :value="howMuchAchived"/>
       </div>
          <ion-item v-for="item in items" :key="item.id">
            <ion-avatar slot="start">
             <ion-icon color="warning" size="large" :icon="sparkles" v-if="item.success"/>
             <ion-icon size="large" color="medium" :icon="rocketOutline" v-else/>
            </ion-avatar>
            <ion-label v-if="item.success">
              <h2>
                  {{item.title}}
              </h2>
              <p>{{item.mission}}</p>
            </ion-label>
            <ion-label v-else>
               <h2>Day {{item.no}} 未挑戦</h2>
            </ion-label>
            <ion-label slot="end">
              <small v-if="item.success">Day{{item.no}}</small>
            </ion-label>
          </ion-item>
          </div>
        </transition>
  </ion-content>
 </ion-page>
</template>

<script>
import { IonPage,
         IonContent,
         IonProgressBar,
          IonListHeader,
         IonItem,
         IonLabel
          } from '@ionic/vue';
import  SyaberuMenu from './SyaberuMenu.vue';
import { 
rocketOutline,
sparkles
} from 'ionicons/icons';
import  getMission from './helper/getMission.js';
/** Const section */
const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
const ACHIVEMENT = FUNCTIONS_HOST_NAME + "/achivement";
export default  {
  name: 'MissionBoard',
  components: { 
    IonContent, 
    IonPage,
    SyaberuMenu,
         IonProgressBar,
         IonListHeader,
         IonItem,
         IonLabel
     },
  setup() {
      return {
        rocketOutline,
        sparkles
      }
  },
  data(){
    return {
      items : [],
      howMuchAchived : 0,
      total : 0,
      currentStage : 0,
      display : false,
    }
  },
  async mounted(){
    const user = this.$store.state.activeUser;
    this.tenantId = user.tenantId;
    this.userId = user.userId;
    await this.load(this.tenantId,this.userId);
  },
  methods : {
    async loadAchivement(tenantId,userId){
      let params = { "params" : {
        'tenantId' : tenantId,
        'userId' : userId,
      }};
      return this.axios.get(ACHIVEMENT,params);
    },
    async load(tenantId,userId){
      this.$root.startLoading();
      let tmpMissions = await getMission(this.axios,tenantId,userId,this.$store);
      const achivement = await this.loadAchivement(tenantId,userId);
      this.currentStage = achivement.data.stage;
      tmpMissions.forEach(tM => {
        tM.missions.forEach((mission,idx) => {
          this.total = idx;
          if(mission.no <= this.currentStage){
            mission.success = true;
          }else{
            mission.success = false;
          } 
          this.items.push(mission);
        });
      });
      this.howMuchAchived = this.currentStage / this.total; 
      this.display = true;
      this.$root.endLoading();
    }
  }
}
</script>
<style>
</style>
