export default async function getMissions(axios,tenantId,userId,cache) {
  //1: check having cache or not
  if(cache.state.missions){
    return cache.state.missions;
  }
  //2: first time, no cache then fetch
  //load mission from the cache
  const FUNCTIONS_HOST_NAME = 'https://us-central1-attendancerecorder-admin.cloudfunctions.net';
  const MISSION = FUNCTIONS_HOST_NAME + "/mission";
  let params = { "params" : {
  'tenantId' : tenantId,
  'userId' : userId,
  }};
  let response = await axios.get(MISSION,params);
  let missions = response.data;
  if(missions.length == 0){
    params.params.isPreset = true;
    let presetResponse = await axios.get(MISSION,params);
    missions = presetResponse.data
  }
  cache.commit('setMissions',missions);
  return missions;
}